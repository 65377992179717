<template>
  <div class="mb-2 text-left">
    <div class="bg-white" v-if="!hideForm">
      <div class="bg-orange guest-form-header">
        <div class="text-title --c-white text-center font-weight-bold pt-3 pb-3">
          {{ $t("reservationAndUsageHistory.changeTVPPrePayment") }}
        </div>
      </div>
      <div class="py-4 mb-4" :class="{'px-4': !inDialog}">
        <TVPPrePaymentItem
          class="mb-5"
          :accommodationFee="currentAccommodationFee.jpy"
          :currentPoint="tvp"
          :prePayment="remainingPrePayment"
        />
        <TVPPrePaymentItem
          class="mb-5"
          ref="afterChange"
          afterChange
          v-model="newPrePayment"
          :accommodationFee="accommodationFeeAfterChange"
          :currentPoint="tvp"
          :prePayment="remainingPrePayment"
          :disabled="disabled"
        />
        <div class="text-center --c-dark-red">
          {{ acceptsSameDayBooking ? 'ご宿泊開始日の１５時まで変更することができます' : 'ご宿泊の前日まで変更することができます。' }}
        </div>
      </div>

    </div>

    <div>
      <AppBtn no-dc v-if="onChange" @click="() => onChange(newPrePayment)" :disabled="!canSave">
        {{ onChangeText || $t('buttons.change') }}
      </AppBtn>
      <AppBtn no-dc v-if="onCancel" @click="onCancel" >
        {{ $t('buttons.cancel') }}
      </AppBtn>
    </div>
  </div>
</template>

<script>
import { selectedBookingComputed } from '../../../selected-booking.mixin.js'
import TVPPrePaymentItem from './TVPPrePaymentItem.vue'
import AppBtn from '@/components/Common/AppBtn.vue'

export default {
  name: 'TVPPrePaymentChangeForm',
  mixins: [selectedBookingComputed],
  props: {
    disabled: Boolean,
    newAccommodationFee: Number,
    onChange: Function,
    onCancel: Function,
    onChangeText: String,
    hideIfCurrentlyZero: Boolean,
    value: Number,
    inDialog: Boolean,
    defaultValue: Number,
    parentCanSave: {
      type: Boolean,
      default: true
    }
  },
  components: {
    TVPPrePaymentItem,
    AppBtn
  },
  async mounted () {
    await this.$doLoading(async () => {
      await this.$store.dispatch('loadRemainingPoints')
    })
  },
  data () {
    return {
      newPrePayment: this.defaultValue ?? null,
      valid: false
    }
  },
  computed: {
    canSave () {
      return this.parentCanSave && (this.valid || this.hideForm)
    },
    tvp () {
      return this.$store.getters.remainingTvp
    },
    accommodationFeeAfterChange () {
      return this.newAccommodationFee ?? this.currentAccommodationFee.jpy
    },
    hideForm () {
      return this.hideIfCurrentlyZero && this.remainingPrePayment === 0
    }
  },
  watch: {
    newPrePayment (v) {
      this.$nextTick(() => {
        this.validate()
        if (this.valid) {
          this.$emit('input', this.newPrePayment)
        } else {
          this.$emit('input', null)
        }
      })
    },
    valid: {
      immediate: true,
      handler (valid) {
        this.$emit('update:valid', valid)
      }
    },
    value: {
      immediate: true,
      handler (newValue) {
        this.newPrePayment = newValue ?? this.defaultValue
        this.$nextTick(() => {
          this.validate()
        })
      }
    },
    newAccommodationFee: {
      immediate: true,
      handler (v) {
        if (v === 0) {
          // then the new value must be zero
          this.$nextTick(() => {
            this.newPrePayment = 0
          })
        }
      }
    }
  },
  methods: {
    validate () {
      this.valid = this.$refs.afterChange.validate()
    }
  }
}
</script>
