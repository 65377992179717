<template>
  <div>
    <TVPPrePaymentChangeForm
      v-if="step === 'input'"
      :onChange="change"
    />
    <div v-else>
      <div class="bg-white">
        <div :class="rowClass">
          <div :class="labelClass">
            {{ accommodationFeeName }}
          </div>
          <div :class="valueClass">
            {{ currentAccommodationFee.onlyAccommodationFeeJpy | toThousands }}円
          </div>
        </div>
        <v-divider />
        <div :class="rowClass">
          <div :class="labelClass">
            合計料金
          </div>
          <div :class="valueClass">
            {{ currentAccommodationFee.jpy | toThousands }}円
          </div>
        </div>
        <div :class="rowClass">
          <div :class="labelClass">
            ポイント利用
          </div>
          <div :class="valueClass">
            {{ newPrePayment | toThousands }}ポイント
          </div>
        </div>
        <div :class="rowClass">
          <div :class="labelClass">
            現地お支払い金額
          </div>
          <div :class="valueClass">
            {{ (currentAccommodationFee.jpy - newPrePayment) | toThousands }}円
          </div>
        </div>
      </div>

      <div class="text-center">
        <AppBtn
          no-dc
          @click="confirm"
          class="my-3"
        >
          確認
        </AppBtn>
      </div>
    </div>
  </div>
</template>

<script>
import { selectedBookingComputed } from '../../../selected-booking.mixin.js'
import TVPPrePaymentChangeForm from './TVPPrePaymentChangeForm'
import AppBtn from '@/components/Common/AppBtn.vue'

export default {
  name: 'TVPPrePaymentChange',
  mixins: [selectedBookingComputed],
  components: {
    TVPPrePaymentChangeForm,
    AppBtn
  },
  props: {
    originalBack: Function,
    setBackButtonAction: Function
  },
  data () {
    return {
      step: 'input',
      newPrePayment: null
    }
  },
  computed: {
    labelClass () {
      return 'text-title --c-prm font-weight-bold'
    },
    valueClass () {
      return 'text-title font-weight-medium --c-bg text-right'
    },
    rowClass () {
      return 'd-flex justify-space-between align-center py-3 px-5'
    }
  },
  mounted () {
    this.setBackButtonAction(this.handleBack)
  },
  destroyed () {
    this.setBackButtonAction(null)
  },
  methods: {
    handleBack () {
      if (this.step === 'input') {
        this.originalBack()
      } else {
        this.step = 'input'
      }
    },
    change (newPrePayment) {
      this.newPrePayment = newPrePayment
      this.step = 'confirm'
    },
    async confirm () {
      await this.$doLoading(async () => {
        await this.$showGqlError(async () => {
          await this.$store.dispatch('updatePrePayment', {
            bookingId: this.id,
            prePayment: this.newPrePayment
          })
          this.setBackButtonAction(null)
        })
      })

      this.completeChange()
    }
  }
}
</script>
