<template>
  <div class="tvp-payment-item">
    <v-form ref="form">
      <div class="--w-medium">{{ select('現在', '変更後') }}</div>
      <div>宿泊料金合計：{{ accommodationFee | toThousands }}円</div>
      <div class="d-flex align-baseline">
        利用予定ポイント数：
        <div v-if="afterChange" style="width: 100px">
          <v-text-field
            type="number"
            dense
            flat
            :rules="[$rules.required, $rules.maxInt(accommodationFee, {
              message: '利用ポイント数が宿泊料金を超えています。'
            }), $rules.maxInt((prePayment || 0) + currentPoint, {
              message: '利用ポイント数が所有ポイントを超えています。'
            }), $rules.minInt(0)]"
            :value="value"
            @input="emitInput($event)"
          />
        </div>
        <span v-else>{{ prePayment | toThousands }}</span>
        ポイント</div>
      <div class="text-small --c-prm">（上記差し引き後ポイント残数：{{ pointRemainsAfterChange | toThousands }}ポイント）</div>
      <div class="--w-medium">お支払い金額合計：{{ paymentTotal | toThousands }}円</div>
    </v-form>
  </div>
</template>

<script>
export default {
  props: {
    afterChange: Boolean,
    prePayment: Number,
    value: Number, // will be the new prePayment amount
    accommodationFee: Number,
    currentPoint: Number
  },
  computed: {
    pointRemainsAfterChange () {
      if (this.afterChange) {
        return this.currentPoint + (
          (this.prePayment ?? 0) - (this.value ?? 0)
        )
      }
      return this.currentPoint
    },
    paymentTotal () {
      if (this.afterChange) {
        return this.accommodationFee - (this.value ?? 0)
      }
      return this.accommodationFee - this.prePayment
    }
  },
  methods: {
    select (beforeChange, afterChange) {
      return this.afterChange ? afterChange : beforeChange
    },
    emitInput (value) {
      if (value === '' || value === null || typeof value === 'undefined') {
        this.$emit('input', null)
      } else {
        this.$emit('input', parseInt(value, 10))
      }
    },
    validate () {
      return this.$refs.form.validate()
    }
  }
}
</script>

<style lang="scss" scoped>
.tvp-payment-item {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid var(--color__b5-gray);
  background-color: var(--color__f7-gray);
}
</style>
